import { UserService } from "./../pages/cadastro/usuario/services/user.service";
import { Component, OnInit } from "@angular/core";
import PerfectScrollbar from "perfect-scrollbar";
import { VersionamentoService } from "./versionamento.service";
import { UserControllerService } from "../core/services/outros/user-controller.service";
import {
  PermissionStorageModel,
  UserPermissionsService,
} from "../core/services/outros/user-permissions.service";
import { ActivatedRoute, Router } from "@angular/router";
import { RoyaltiesService } from "../shared/services/royalties.service";

declare const $: any;

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype?: string;
  collapse?: string;
  children?: ChildrenItems[];
  role?: string;
}

export interface ChildrenItems {
  path: string;
  title: string;
  icontype?: string;
  ab?: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems[];
  role?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/home",
    title: "HOME",
    type: "link",
    icontype: "home",
    role: "dashboard",
  },
  {
    path: "/cadastro",
    title: "CADASTROS",
    type: "sub",
    icontype: "queue",
    collapse: "cadastro",
    role: "cadastro",
    children: [
      { path: "ecv", title: "- ECV", role: "ecv" },
      { path: "grupoecv", title: "- FILIAL", role: "grupoecv" },
      { path: "usuario", title: "- USUÁRIOS", role: "usuario" },
      {
        path: "clientes",
        title: "- CLIENTES / FORNECEDOR",
        role: "clientes",
      },
    ],
  },
  {
    path: "/operacao",
    title: "VISTORIAS",
    type: "sub",
    icontype: "content_paste",
    collapse: "operacao",
    role: "operacao",
    children: [
      { path: 'agenda', title: '- AGENDA', role: 'agendamento' },
      {
        path: "bancoDeImagens",
        title: "- CONSULTAR CHASSI/MOTOR",
        role: "imageBank",
      },
      { path: "consultaWS", title: "- CONSULTAR DETRAN", role: "consultaWS" },
      { path: "vistorias", title: "- CONSULTAR VISTORIAS", role: "vistorias" },
    ],
  },
  {
    path: "/credito",
    title: "CRÉDITOS",
    type: "sub",
    icontype: "monetization_on",
    collapse: "credito",
    role: "view_credits",
    children: [
      {
        path: "consultarCreditos",
        title: "- CONSULTAR CRÉDITOS",
        role: "view_credits",
      },
    ],
  },
  {
    path: "/suporte",
    title: "MESA DE ANÁLISE",
    type: "sub",
    icontype: "photo_library",
    collapse: "mesa",
    role: "desk_vistoria",
    children: [
      {
        path: "desk_vistoria",
        title: "- IMAGENS/FILMAGENS",
        role: "desk_vistoria",
      },
    ],
  },
  {
    path: "/notafiscal",
    title: "NOTA FISCAL",
    type: "sub",
    icontype: "card_travel",
    collapse: "notaFiscal",
    role: "financeiro",
    children: [
      {
        path: "emissaoDeNotafiscal",
        title: "- EMISSÃO",
        role: "geracaoDeNotaFiscal",
      },
      {
        path: "consultarNotaFiscal",
        title: "- CONSULTAR NFSe",
        role: "geracaoDeNotaFiscal",
      },
    ],
  },
  {
    path: "/financeiro",
    title: "FINANÇAS",
    type: "sub",
    icontype: "attach_money",
    collapse: "financeiro",
    role: "financeiro",
    children: [
      { path: "contasapagar", title: "- CONTAS A PAGAR", role: "clientesECV" },
      { path: "contasareceber", title: "- CONTAS A RECEBER", role: "financas" },
      {
        path: "compradeservicotaxa",
        title: "- COMPRA DE SERVIÇOS / TAXA",
        role: "geracaoDeNotaFiscal",
      },
      {
        path: "relatoriosfinanceiro",
        title: "- RELATÓRIOS ",
        role: "clientes",
      },
    ],
  },
  {
    path: "/auditoriaDETRAN",
    title: "AUDITORIA DETRAN",
    type: "sub",
    icontype: "location_searching",
    collapse: "auditoriDetran",
    role: "financeiro",
    children: [
      { path: "relatoriosauditoria", title: "- RELATÓRIOS ", role: "clientes" },
    ],
  },
  {
    path: "/comercial",
    title: "FINANCEIRO/COMERCIAL",
    type: "sub",
    icontype: "phone_android",
    collapse: "financeiroComercial",
    role: "financeiro",
    children: [
      { path: "medicao", title: "- MEDIÇÃO", role: "clientes" },
      { path: "dashboard", title: "- DASHBOARD", role: "dashboard" },
      {
        path: "creditoecv",
        title: "- CRÉDITO ECV",
        role: "geracaoDeNotaFiscal",
      },
      {
        path: "faturarmensal",
        title: "- FATURAR MENSAL",
        role: "gerenciamentoDeCredito",
      },
      {
        path: "relatorioscomercial",
        title: "- RELATORIOS",
        role: "clientesECV",
      },
    ],
  },
  {
    path: "/financeiro",
    title: "CRM",
    type: "sub",
    icontype: "dvr",
    collapse: "crm",
    role: "financeiro",
    children: [],
  },
  {
    path: "/suporte",
    title: "SUPORTE SERV DESK",
    type: "sub",
    icontype: "settings",
    collapse: "suporte",
    role: "suporte",
    children: [
      { path: "auditoria", title: "- AUDITORIA", role: "auditoria" },
      { path: "suporteN2", title: "- SUPORTE N2", role: "suporteN2" },
      {
        path: "desk_finance",
        title: "- MESA DE PAGAMENTO",
        role: "desk_finance",
      },
      {
        path: "xml-detran",
        title: "- RETORNOS XML DETRAN",
        role: "desk_vistoria",
      },
    ],
  },
  {
    path: "/gerenciamento",
    title: "SUPORTE DEV",
    type: "sub",
    icontype: "devices",
    collapse: "gerenciamento",
    role: "gerenciamento",
    children: [
      {
        path: "codigodetran",
        title: "- CONSULTAR CÓDIGO DETRAN",
        role: "codigodetran",
      },
      {
        path: "itensdereprovacao",
        title: "- CONSULTAR ITENS DE REPROVAÇÃO",
        role: "itensdereprovacao",
      },
      {
        path: "parametros",
        title: "- CONSULTAR PARÂMETROS",
        role: "parametros",
      },
      {
        path: "servicodetran",
        title: "- CONSULTAR SERVIÇO DETRAN",
        role: "servicodetran",
      },
      {
        path: "mensagemDeAlerta",
        title: "- MESSAGEM DE ALERTA",
        role: "mensagemDeAlerta",
      },
      { path: "midia", title: "- TIPOS DE MÍDIAS", role: "grupoecv" },
    ],
  },
  {
    path: "/ajuda",
    title: "AJUDA",
    type: "sub",
    icontype: "help_outline",
    collapse: "ajuda",
    role: "ajuda",
    children: [{ path: "manuais", title: "MANUAIS", role: "manuais" }],
  }
];

@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ["sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  version: string = "versão 2.0.0";
  user: any = "";
  firstName: any = "";
  royaltiesValue = "";
  isVisibile = false;

  public menuItems: any[];
  ps: any;
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  constructor(
    private service: VersionamentoService,
    private userControllerService: UserControllerService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private royaltiesService: RoyaltiesService
  ) {
    // this.service.getVersionamento().subscribe(
    //     //next => this.version = next;
    // )
  }

  ngOnInit() {
    this.menuItems = this.menuPermission();

    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector(".sidebar .sidebar-wrapper")
      );
      this.ps = new PerfectScrollbar(elemSidebar);
    }

    setTimeout(() => {
      if (localStorage.getItem("res")) {
        this.user = JSON.parse(localStorage.getItem("res"));
        this.firstName = this.user.name.split(" ")[0];
        this.getEcvUser()
      }
    }, 1000);
  }

  onShowDetails(guid: string) {
    this.router.navigate([`/cadastro/usuario/visualizar/${guid}`], {
      relativeTo: this.route,
    });
  }

  onEdit(guid: string): void {
    this.router.navigate([`/cadastro/usuario/editar/${guid}`], {
      relativeTo: this.route,
    });
  }

  menuPermission(): RouteInfo[] {
    const roles: any = this.userControllerService.getRoles().roles;

    // let permissions = {
    //     roles: [
    //         'dashboard',
    //         'gerenciamento', 'codigodetran', 'itensdereprovacao', 'parametros', 'servicodetran', 'mensagemDeAlerta',
    //         'cadastro', 'usuario', 'ecv', 'grupoecv',
    //         'operacao', 'vistorias', 'vistoriaRetorno', 'agendamento', 'consultaOS', 'consultaWS',
    //         'financeiro', 'clientes', 'clientesECV', 'relatorioFinanceiro', 'gerenciamentoDeCredito', 'geracaoDeNotaFiscal', 'OMIE', 'renovaTI', 'financas', 'faturamento',
    //         'suporte', 'auditoria', 'retornoDETRAN', 'suporteN2',
    //         'ajuda'
    //     ]
    // }

    let menu = [];

    ROUTES.forEach((route) => {
      if (roles.includes(route.role)) {
        let item = route;
        let children = route.children;
        item.children = [];
        children?.forEach((child) => {
          if (roles.includes(child.role)) {
            item.children.push(child);
          }
        });

        menu.push(item);
      }
    });

    return menu;
  }

  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  expandOrCollapseMenu(id) {
    let parent = document.getElementById(id + "-p");
    let child = document.getElementById(id);
    parent.ariaExpanded = parent.ariaExpanded === "true" ? "false" : "true";
    child.style.height =
      child.style.height === "0px" || child.style.height === "" ? "100%" : "0";
  }

  getEcvUser() {
    this.userService.getByID(this.user.id).subscribe((userRes) => {
      if (!userRes.ecVs || userRes.ecVs.length === 0) {
        return;
      }
      
      const ecvId = userRes.ecVs[0].id;
      
      this.royaltiesService.getByID(ecvId).subscribe((royaltiesRes) => {
        if (!royaltiesRes) {
          return;
        }
  
        this.royaltiesValue = royaltiesRes.balance;
        this.isVisibile = true;
      });
    });
  }

  logout() {
    this.userControllerService.logout();
  }
}
